<template>
  <base-card padding="30px 26px" :margin="margin">
    <div class="action-panel">
      <div v-if="enabled">
        <div class="row action-panel__top">
          <div class="col">
            <FormulateInput
              input-class="w-100 justify-content-center actions-panel__submit"
              @click.native="save()"
              :type="saveBtnType"
              :disabled="saveBtnDisabled"
            >
              <span class="submit-inner" :class="{ loading: buttonLoader }">
                <span v-if="charges_count_show">{{
                  `${save_label} ${charges_count} ${charges}`
                }}</span>
                <span v-else>{{ `${save_label}` }}</span>
                <FadeLoader
                  class="loader"
                  :key="buttonLoader"
                  :loading="buttonLoader"
                  :color="loaderColor"
                  :margin="'0'"
                  :height="'4px'"
                  :width="'4px'"
                  :radius="'10px'"
                />
              </span>
            </FormulateInput>
          </div>
        </div>
      </div>
      <div class="action-panel__body">
        <template v-for="(item, last_index) in last_action">
          <LastAction
            :link="'link_test'"
            :key="last_index"
            :label="item.label"
            :subject="item.subject"
            :subject_link="item.subject_link"
            :date="item.date"
          />
        </template>

        <template v-if="showActionLabel">
          <div
            v-for="label in action_label"
            :key="label.label"
            :class="label.icon"
          >
            <template>
              <ActionLabel
                :action_label="
                  (() => {
                    label.disabled = !actionsEnabled;
                    return label;
                  })()
                "
              />
            </template>
          </div>
        </template>

        <template v-if="display_label && display_label.length >= 0">
          <div
            v-for="(item, idx) in display_label"
            :key="idx + 'display_label'"
          >
            <DisplayLabel
              v-if="item"
              :label="item.label"
              :value="item.value"
              :link="item.link"
            />
          </div>
        </template>
        <template v-else>
          <div v-if="display_label">
            <DisplayLabel
              :label="display_label.label"
              :value="display_label.value"
              :link="display_label.link"
            />
          </div>
        </template>
      </div>
    </div>
  </base-card>
</template>

<script>
import DisplayLabel from "@/components/Labels/DisplayLabel";
import ActionLabel from "@/components/Labels/ActionLabel";
import LastAction from "@/components/Helpers/LastAction";
import BaseCard from "@/components/Cards/BaseCard";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
const colors = require("/colors.config.json");
export default {
  components: {
    DisplayLabel,
    ActionLabel,
    LastAction,
    BaseCard,
    FadeLoader,
  },

  data() {
    return {
      buttonLoader: false,
    };
  },

  props: {
    save_label: {
      type: String,
      // required: true,
    },
    charges: {
      type: String,
      // required: true,
    },
    action_label: {
      type: Array,
      // required: true
    },
    charges_count: {
      type: String,
      default: "",
    },
    charges_count_show: {
      type: Boolean,
      default: true,
    },
    last_action: {
      type: Array,
      // required: true
    },
    horizontal_label: {
      type: Object,
      // required: true
    },
    display_label: {
      type: [Object, Array],
    },
    saveFN: {
      type: Function,
    },
    publishFN: {
      type: Function,
    },
    deleteFN: {
      type: Function,
    },
    saveBtnType: {
      type: String,
      default: "submit",
    },
    saveBtnDisabled: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: String,
      default: "0 0 30px",
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    actionsEnabled: {
      type: Boolean,
      default: true,
    },
    showActionLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loaderColor() {
      return this.saveBtnDisabled ? `${colors.gray_text}` : `${colors.white}`;
    },
  },
  methods: {
    save() {
      this.saveFN();
    },
    publish() {
      this.publishFN();
    },
    delete() {
      this.deleteFN();
    },
  },
  mounted() {
    this.$root.$on("xForm-typing", (status) => (this.buttonLoader = status));
    this.$root.$on(
      "xMultiForm-typing",
      (status) => (this.buttonLoader = status)
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.action-panel {
  // &__top {}

  &__body > div {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $light_gray;

    &:last-child {
      padding-bottom: 0;
      border: unset;
    }
  }

  ::v-deep button {
    width: 100% !important;

    span {
      margin: 0 auto;
    }
  }

  .delete {
    width: auto;
    float: right;
    margin-top: 10px;
  }
}

::v-deep .actions-panel__submit {
  font-size: 0.8rem !important;

  .submit-inner {
    position: relative;

    &.loading {
      padding-right: 25px;

      .loader {
        color: $gray_text;
        position: absolute !important;
        right: 0;
        top: 40%;
        transform: translateY(-50%);
      }
    }
  }
}

::v-deep .details__last-action {
  .last-action {
    &__title {
      color: $dark;
    }
    &__user-name,
    &__time {
      color: $purple_blue;
    }
  }
}
</style>
