<template>
  <div class="pim-display-label">
    <p>{{ label }}:</p>

    <p>
      <b-link :to="link">{{ value }}</b-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "display-label",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.pim-display-label {
  width: 100%;
}

.pim-display-label p {
  font-weight: normal;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 5px;
}

.pim-display-label p:last-child {
  margin-bottom: 0px;

  a {
    color: $dark;
    font-weight: 700;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
