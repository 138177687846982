<template>
  <div class="action-label_wrapper">
    <div>
      <span>{{ action_label.label }}</span>
    </div>
    <div
      class="action_label-view"
      v-bind:class="{ 'justify-content-between': action_label.icon, disabled }"
    >
      <span class="action_label-count">{{ action_label.count }}</span>
      <span
        v-if="action_label.icon"
        class="action_label-icon"
        @click="actionFN"
      >
        <font-awesome-icon :icon="action_label.icon" />
      </span>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";

import { faFile, faRedo } from "@fortawesome/free-solid-svg-icons";

library.add(faFile, faRedo);

export default {
  name: "ActionLabel",
  props: {
    action_label: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    actionFN() {
      this.action_label.actionFN();
    },
  },
  computed: {
    disabled() {
      if (this.action_label.disabled) {
        return "disabled";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.action-label_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 0.875rem;
    //color: #7f8c8d;
  }

  .action_label-view {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 25%;
  }

  .action_label-view.disabled {
    pointer-events: none;
  }

  .action_label-count {
    color: $purple_blue;
  }

  .action_label-icon {
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $gray_text_2;
    opacity: 1;
    transition: 0.3s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: -10px -10px -10px 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;

    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
