<template>
  <div class="details__last-action">
    <div class="last-action__title">{{ label }}:</div>
    <div class="last-action__footer">
      <div
        class="last-action__user-name"
        v-if="subject"
        :class="{ 'w-100': !date }"
      >
        <a v-if="link" :to="link">{{ subject }}</a>
        <a v-else>{{ subject }}</a>
      </div>
      <div
        class="last-action__time"
        :class="{ 'w-100 text-left': !subject }"
        v-if="date"
      >
        {{ date | moment("DD-MM-YYYY HH:mm") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastAction",
  props: {
    label: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    subject: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.details__last-action {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;

  .last-action {
    &__title {
      width: 100%;
      color: $light_text;
      font-size: 0.875rem;
      font-weight: 400;
    }

    &__footer {
      width: 100%;
      display: flex;
    }
    &__user-name,
    &__time {
      flex: 1 1 auto;
      min-width: fit-content;
      font-size: 0.875rem;
      font-weight: 400;
      color: $light_text;
      text-transform: capitalize;
      width: 50%;
      line-height: 100%;
      margin-top: 8px;

      a:hover {
        color: #2d9cdb;
      }
    }

    &__time {
      text-align: right;
    }
  }
}
</style>
